import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import LoginRegisterForm from 'Components/UI/LoginRegisterForm'
import styles from './index.module.scss'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import Form from 'Components/UI/Form'
import Checkbox from 'Components/UI/Checkbox'

class Login extends Component {
  state = {
    newsletterChecked: false
  }
  constructor(props) {
    super(props)
    this.guestForm = React.createRef()
    this.checkbox = React.createRef()
    if (typeof window !== `undefined`) this.checkRedirection()
  }
  componentDidMount() {
    AppStore.on(AppConstants.SET_CUSTOMER, this.onSetCustomer)
    AppStore.on(AppConstants.LOGIN, this.onCustomerLogin)
    this.checkRedirection()
    setTimeout(AppActions.resetCustomer, 0, { type: AppConstants.GUEST_CUSTOMER, keys: [] })
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    this.checkRedirection()
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  checkRedirection = () => {
    const customer = AppStore.getCustomer()
    if (customer && customer.id) navigate(`/checkout/customer/billing`)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.SET_CUSTOMER, this.onSetCustomer)
    AppStore.off(AppConstants.LOGIN, this.onCustomerLogin)
  }
  onSetCustomer = () => {
    navigate(`/checkout/guest/billing`)
  }
  onCustomerLogin = () => {
    const customer = AppStore.getCustomer()
    if (customer && customer.id) navigate(`/checkout/customer/billing`)
  }
  onLoginCustomer = () => {
  }
  onNewsletterCheckboxChange = () => {
    this.setState( { newsletterChecked: this.checkbox.current.state.checked  })
  }
  onGuestContinue = () => {
    const email = this.guestForm.current.refs.guestMail.state.value
    const subscribeToNewsletter = this.state.newsletterChecked
    setTimeout(AppActions.setCustomer, 0, { email, type: AppConstants.GUEST_CUSTOMER })
    if (subscribeToNewsletter) setTimeout(AppActions.subscribeToNewsletter, 0, { email, silenced: false })
  }
  render() {
    const guestForm = [
      {
        type: 'input',
        props: {
          id: 'guestMail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Email Address',
          required: true
        }
      }
    ]
    return (
      <Layout>
        <div className={`${styles.parent}`}>
          <div className={`u-height--only-lg--20`}></div>
          {/* <div className='u-height--lg--80' /> */}
          <section className='u-col-lg-10 u-offset-lg-1'>
            <div className={`u-row`}>
              <div className={`u-col-lg-5`}>
                <p className={`u-inline-block t-title-2 u-pad-b--10 u-uppercase`}>Guest Customer</p>
                <p className={`t-paragraph-0 t-color-gray`}>You may check out without creating an account. You will be able to create an account later if you wish.</p>
                <Form className={`u-remove-autofill`} items={guestForm} submit={this.onGuestContinue} ref={this.guestForm}>
                  <div className='u-pad-t--20'>
                    <Checkbox
                      id='newsletter'
                      className='t-paragraph-0 u-pad-b--20'
                      value='newsletter'
                      onChange={this.onNewsletterCheckboxChange}
                      label='Subscribe to receive Stella Luna e-mails with access to our latest collections, events and initiatives.'
                      ref={this.checkbox}
                      checked={this.state.newsletterChecked}
                    />
                    <BackgroundBtn
                      className='u-col-lg-6'
                      text='Continue'
                      type='submit'
                      fullBlack
                      highlighted
                    />
                    <p className={`t-paragraph-0 t-color-gray u-pad-t--10`}>By choosing 'continue', you accept the terms of our <a href='/privacy-statement' className={`u-simple-hyperlink`} target={`_blank`}>privacy statement</a>.</p>
                  </div>
                </Form>
              </div>
              <div className='u-height--only-lg--60' />
              <div className={`u-col-lg-5 u-offset-lg-2`}>
                <LoginRegisterForm
                  toRegister={false}
                  hideRegister={true}
                  onLoginCustomer={this.onLoginCustomer}
                />
              </div>
            </div>
          </section>
          <div className={`u-height--only-lg--20`}></div>
          <div className='u-height--lg--80' />
        </div>
      </Layout>
    )
  }
}

export default Login
